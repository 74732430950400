@import './variables';

.ptro-holder-wrapper {
    z-index: 2000;

    .ptro-wrapper {
        background-color: $color-grey-20;
    }

    .ptro-color-main {
        background-color: $color-grey-80;
        color: $color-grey-20;
    }

    .ptro-bar {
        span {
            &.ptro-bar-right {
                position: fixed;
                top: 35px;
                right: 35px;
                float: none;
                z-index: 2001;

                @media screen and (max-width: 768px) {
                    top: 0;
                    right: 0;
                }
            }
        }
    }

    .ptro-icon-btn {
        border-radius: 5px;

        @media screen and (max-width: 768px) {
            margin: 4px 0 0 2px !important; // sass-lint:disable-line no-important
        }
    }

    .ptro-color-control {
        background-color: $color-grey-20;

        &:hover {
            background-color: darken($color-grey-20, 10%) !important; // sass-lint:disable-line no-important

            &.ptro-color-active-control {
                background-color: darken($color-blue, 10%) !important; // sass-lint:disable-line no-important
            }
        }

        &.ptro-color-active-control {
            background-color: $color-blue;
        }
    }

    .ptro-resize {
        display: none;
    }
}
