.animation-spin {
    animation: spin 1s infinite;
    display: inline-block;
}

.animation-slide-ttb {
    animation: slide-ttb 1s infinite;
    display: inline-block;
}

.animation-cycle {
    animation: cycle 1s infinite reverse;
    display: inline-block;
}

@keyframes spin {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    50% {
        transform: rotate(180deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes slide-ttb {
    0%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }

    50% {
        animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }

    0% {
        transform: translate(0, 0);
    }

    49.9% {
        transform: translate(0, 200%);
    }

    50% {
        transform: translate(0, -200%);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes cycle {
    0%,
    50%,
    100% {
        animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5);
    }

    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
