:root {
  --tab-icon-height: 32px;
}

.pull-to-refresh-off {
  overscroll-behavior: none;
}

body {
  margin: 0;
}
body .text-field-label {
  white-space: nowrap;
}
body .mat-mdc-menu-panel {
  padding: 10px;
}
body .mat-mdc-menu-panel .mat-mdc-menu-item {
  display: flex;
  height: 56px;
  align-items: center;
  justify-content: flex-start;
}
body .mat-mdc-menu-panel .mat-mdc-menu-item .mat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
body .mat-mdc-menu-panel .mat-mdc-menu-content:not(:empty) {
  padding-top: 0;
  padding-bottom: 0;
}
body .mat-icon {
  display: flex;
  width: unset;
  height: unset;
}
body .mat-mdc-mini-fab {
  display: flex;
  justify-content: center;
  align-items: center;
}
body .mat-expansion-panel-header {
  height: 4rem;
}
body .mat-expansion-panel-header:focus {
  border: unset !important;
  background: inherit !important;
}
body .mat-accordion .mat-expansion-panel {
  width: unset;
}
body .mat-mdc-card {
  width: unset;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}
body .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #da1f3d;
}
body .mat-mdc-dialog-container {
  min-width: 21.5rem;
  overscroll-behavior: contain;
}
body .mat-mdc-dialog-container .mat-mdc-dialog-content {
  margin: 0;
  padding: 0;
  overflow-y: unset;
}
body .mat-mdc-dialog-container .mat-mdc-dialog-actions {
  margin-bottom: 0;
  gap: 0.5rem;
}
body .mat-mdc-input-element {
  color: var(--primary-darker-color);
}
body .time-picker-toggle .ngx-material-timepicker-toggle,
body .mat-mdc-icon-button.mat-mdc-button-base {
  color: var(--primary-color);
}
body .mat-mdc-form-field .mat-mdc-form-field-icon-suffix {
  padding: 0 0.25rem;
  color: var(--primary-color);
}
body .mdc-line-ripple--active {
  opacity: 0;
}
body mat-icon,
body mat-icon.mat-primary {
  color: var(--primary-color);
}
body mat-icon.mat-icon-no-color,
body mat-icon.mat-primary.mat-icon-no-color {
  color: unset;
}
body .mat-badge-content {
  background-color: var(--primary-color);
}
body .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
body .mat-button-toggle-group-appearance-standard {
  border: none;
}
body .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #da1f3d;
  --mdc-slider-focus-handle-color: #da1f3d;
  --mdc-slider-hover-handle-color: #da1f3d;
  --mdc-slider-active-track-color: #da1f3d;
  --mdc-slider-inactive-track-color: #323234;
}
body .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #555;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #dadbdd;
}
body .mat-mdc-button-base.mat-mdc-button:not(a[href]):hover {
  color: #da1f3d;
}
body .mat-mdc-tab-group {
  touch-action: auto !important;
}
body .mat-mdc-option {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

label.required::after {
  content: " *";
}

*:has(.ng-touched.ng-invalid) > label.required::after {
  color: #da1f3d;
}

:root {
  --tab-icon-height: 32px;
}

.mat-mdc-snack-bar-container.snack-bar-service-error {
  margin-bottom: 3rem;
  --mdc-snackbar-container-color: #da1f3d;
  --mdc-snackbar-supporting-text-color: #fff;
  --mat-snack-bar-button-color: #fff;
}
.mat-mdc-snack-bar-container.snack-bar-service-info {
  margin-bottom: 3rem;
  --mdc-snackbar-container-color: #007cb1;
  --mdc-snackbar-supporting-text-color: #fff;
  --mat-snack-bar-button-color: #fff;
}
.mat-mdc-snack-bar-container.snack-bar-service-warning {
  margin-bottom: 3rem;
  --mdc-snackbar-container-color: #ee8b00;
  --mdc-snackbar-supporting-text-color: #fff;
  --mat-snack-bar-button-color: #fff;
}
.mat-mdc-snack-bar-container.snack-bar-service-success {
  margin-bottom: 3rem;
  --mdc-snackbar-container-color: #3f8113;
  --mdc-snackbar-supporting-text-color: #fff;
  --mat-snack-bar-button-color: #fff;
}

.animation-spin {
  animation: spin 1s infinite;
  display: inline-block;
}

.animation-slide-ttb {
  animation: slide-ttb 1s infinite;
  display: inline-block;
}

.animation-cycle {
  animation: cycle 1s infinite reverse;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes slide-ttb {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  0% {
    transform: translate(0, 0);
  }
  49.9% {
    transform: translate(0, 200%);
  }
  50% {
    transform: translate(0, -200%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes cycle {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5);
  }
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
:root {
  --tab-icon-height: 32px;
}

.ptro-holder-wrapper {
  z-index: 2000;
}
.ptro-holder-wrapper .ptro-wrapper {
  background-color: #f2f2f2;
}
.ptro-holder-wrapper .ptro-color-main {
  background-color: #323234;
  color: #f2f2f2;
}
.ptro-holder-wrapper .ptro-bar span.ptro-bar-right {
  position: fixed;
  top: 35px;
  right: 35px;
  float: none;
  z-index: 2001;
}
@media screen and (max-width: 768px) {
  .ptro-holder-wrapper .ptro-bar span.ptro-bar-right {
    top: 0;
    right: 0;
  }
}
.ptro-holder-wrapper .ptro-icon-btn {
  border-radius: 5px;
}
@media screen and (max-width: 768px) {
  .ptro-holder-wrapper .ptro-icon-btn {
    margin: 4px 0 0 2px !important;
  }
}
.ptro-holder-wrapper .ptro-color-control {
  background-color: #f2f2f2;
}
.ptro-holder-wrapper .ptro-color-control:hover {
  background-color: #d9d9d9 !important;
}
.ptro-holder-wrapper .ptro-color-control:hover.ptro-color-active-control {
  background-color: #00587e !important;
}
.ptro-holder-wrapper .ptro-color-control.ptro-color-active-control {
  background-color: #007cb1;
}
.ptro-holder-wrapper .ptro-resize {
  display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
p {
  @apply my-4;
}

h3 {
  @apply mb-4 text-lg font-medium leading-6;
}

h4 {
  @apply text-base font-medium leading-6;
}