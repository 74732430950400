:root {
    --tab-icon-height: 32px;
}

// gtue colors
$color-brand-red: #da1f3d;
$color-brand-grey: #c2c4c6;
$color-black: #000;
$color-white: #fff;
$color-grey-10: #f9f9f9;
$color-grey-20: #f2f2f2;
$color-grey-40: #dadbdd;
$color-grey-50: #888;
$color-grey-60: #555;
$color-grey-80: #323234;
$color-green: #3f8113;
$color-orange: #ee8b00;
$color-red: $color-brand-red;
$color-blue: #007cb1;
// em colors
$color-dev-env: #8d5c59;

// material colors
$label-color: rgba(0, 0, 0, 0.54);
$border-color: rgba(0, 0, 0, 0.12);

$font-family: Roboto, 'Helvetica Neue', sans-serif;
$font-h-1: 500 1.75rem/2rem $font-family;
$font-h-2: 500 1.5rem/1.75rem $font-family;
$font-h-3: 500 1.125rem/1.5rem $font-family;
$font-h-4: 500 1rem/1.5rem $font-family;
$font-body: normal 1rem/1.5rem $font-family;
$font-body-2: normal 1.75rem/2rem $font-family;
$font-button: 500 1rem/1.5rem $font-family;
$font-label: normal 0.875rem/1.25rem $font-family;
$font-label-bold: 500 0.875rem/1.25rem $font-family;
$font-caption: normal 0.75rem/1rem $font-family;
$font-caption-bold: 500 0.75rem/1rem $font-family;

$font-icon: 2.5rem;
$font-size-icon-small: 1.5rem;
$font-size-icon-medium: 2rem;
$font-size-icon-big: 3.5rem;

// global
$padding: 1rem;
$margin: 0.5rem;
$margin-16: 1rem;
$margin-24: 1.5rem;
$margin-32: 2rem;

// table
$table-padding-8: 0.5rem;
$table-padding-16: 1rem;
$table-divider: 0.1rem solid $border-color;

// card
$card-min-width: 21.5rem;
$card-max-width: 30rem;
$card-border-radius: 0.25rem;

// accordion
$accordion-min-width: 22.4rem;
$accordion-max-width: 25rem;

// navigation
$navigation-height: 3.5rem;
$navigation-footer-height: 2.5rem;
$navigation-border-height: 0.2rem;
$navigation-progress-bar-height: 0.2rem;
$navigation-logo-height: 1.75rem;
$navigation-logo-width: $navigation-logo-height * 2.28;
$navigation-padding: $padding;

// produkt-detail
$produkt-detail-navigation-height: 3rem;
$produkt-detail-toolbar-height: 3rem;
$produkt-detail-content-padding: 0.5rem;
$produkt-detail-content-height: $navigation-height + $navigation-footer-height + $produkt-detail-toolbar-height +
    $produkt-detail-navigation-height;
$produkt-detail-content-outer-height: $produkt-detail-content-height + $produkt-detail-content-padding * 2;

$produkt-detail-toggle-padding: $produkt-detail-content-padding;
$produkt-detail-toggle-height: 3.125rem;

$model-scene-height: $produkt-detail-content-outer-height + ($produkt-detail-toggle-padding * 2) +
    $produkt-detail-toggle-height;
$model-scene-min-height: 220px;
