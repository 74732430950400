@font-face {
    font-family: 'Roboto Mono', monospace;
    font-style: normal;
    font-weight: 400;
    src: url(RobotoMono-Regular.woff2) format('woff2');
}

@font-face {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    src: url(Roboto-Regular.woff2) format('woff2');
}
