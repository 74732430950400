@import './variables';

$snack-bar-bottom-margin: $navigation-footer-height + 0.5rem;

.mat-mdc-snack-bar-container {
    &.snack-bar-service-error {
        margin-bottom: $snack-bar-bottom-margin;

        --mdc-snackbar-container-color: #{$color-brand-red};
        --mdc-snackbar-supporting-text-color: #{$color-white};
        --mat-snack-bar-button-color: #{$color-white};
    }

    &.snack-bar-service-info {
        margin-bottom: $snack-bar-bottom-margin;

        --mdc-snackbar-container-color: #{$color-blue};
        --mdc-snackbar-supporting-text-color: #{$color-white};
        --mat-snack-bar-button-color: #{$color-white};
    }

    &.snack-bar-service-warning {
        margin-bottom: $snack-bar-bottom-margin;

        --mdc-snackbar-container-color: #{$color-orange};
        --mdc-snackbar-supporting-text-color: #{$color-white};
        --mat-snack-bar-button-color: #{$color-white};
    }

    &.snack-bar-service-success {
        margin-bottom: $snack-bar-bottom-margin;

        --mdc-snackbar-container-color: #{$color-green};
        --mdc-snackbar-supporting-text-color: #{$color-white};
        --mat-snack-bar-button-color: #{$color-white};
    }
}
